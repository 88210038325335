export default {
  methods: {
    seatsLeft (result) {
      if (result > 8) {
        return '>8'
      } else if (result === 0) {
        return this.$t('tripFull')
      }

      return result
    },
  },
}
